import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import ImagesData from "../../components/images"

const AgneyaSpecialPage = () => (
  <Layout className="body">
    <SEO title="Products" />
    <div className="row">
      <div className="col-sm-1"></div>
      <div className="col-sm-10">
        <div className="row">
          <div className="col">
            <div className="row no-gutters">
              <div className="col-sm-6">
                <Link to="/agneya_special/niranjan">
                  <ImagesData imgName="niranjan_1" className="img-thumbnail" alt="Agneya Special Solar Products" title="Solar Niranjan"/>
                </Link>
              </div>
              <div className="col-sm-6">
                <div className="card-body">
                  <h4 className="card-title">Solar Niranjan</h4>
                  <p className="card-text">
                    <small className="text-muted">
                      <Link className="more" to="agneya_special/niranjan">View details</Link>
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="row no-gutters">
              <div className="col-sm-6">
                <Link to="/agneya_special/laman_diwa">
                  <ImagesData imgName="laman_diwa" className="img-thumbnail" alt="Agneya Special Solar Products" title="Solar Laman Diwa"/>
                </Link>
              </div>
              <div className="col-sm-6">
                <div className="card-body">
                  <h4 className="card-title">Solar Laman Diwa</h4>
                  <p className="card-text">
                    <small className="text-muted">
                      <Link className="more" to="agneya_special/laman_diwa">View details</Link>
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">&nbsp;</div>
        <div className="row">
          <div className="col">
            <div className="row no-gutters">
              <div className="col-sm-6">
                <Link to="/agneya_special/white_samai">
                  <ImagesData imgName="samai_1" className="img-thumbnail" alt="Agneya Special Solar Products" title="Solar Samai"/>
                </Link>
              </div>
              <div className="col-sm-6">
                <div className="card-body">
                  <h4 className="card-title">White metal Solar Samai</h4>
                  <p className="card-text">
                    <small className="text-muted">
                      <Link className="more" to="agneya_special/white_samai">View details</Link>
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="row no-gutters">
              <div className="col-sm-6">
                <Link to="/agneya_special/brass_samai">
                  <ImagesData imgName="samai_2" className="img-thumbnail" alt="Agneya Special Solar Products" title="Solar Samai"/>
                </Link>
              </div>
              <div className="col-sm-6">
                <div className="card-body">
                  <h4 className="card-title">Brass metal Solar Samai</h4>
                  <p className="card-text">
                    <small className="text-muted">
                      <Link className="more" to="agneya_special/brass_samai">View details</Link>
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">&nbsp;</div>
      </div>
      <div className="col-sm-1"></div>
    </div>
  </Layout>
)

export default AgneyaSpecialPage
